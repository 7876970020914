import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export default class DropDown extends Component {
  state = {
    isVisible: false,
    selected: null,
  };
  componentWillReceiveProps(nextProps){
    const { options, isMultiple } = this.props;
    if(nextProps.updated != this.props.updated){
      if(isMultiple){
        this.setState({ selected: nextProps.updated });
      }else{
        var opt = options.filter(option => option.id == nextProps.updated);
        if(opt.length > 0){
          this.setState({ selected: opt[0] });
        }
      }
    }
    if (nextProps.openList != this.props.openList) {
      this.setState({ isVisible: nextProps.openList });
    }
  }

  render() {
    const { isVisible, selected } = this.state;
    const { options, initialOption, onSelect, defaultComponent, openDropDown, backgroundColor, isLast, isMultiple } = this.props;
    const selectedOption = selected ? isMultiple ? selected : selected.value : isMultiple ? initialOption : initialOption.value;
    return (
      <View style={{...styles.container, height: isMultiple ? 'auto' : 60}}>
        <TouchableOpacity
          style={{...styles.button, backgroundColor: isMultiple ? "#D3D3D3" : 'none' }}
          onPress={() => {
            if(!defaultComponent){
              this.setState({ isVisible: !isVisible }, ()=>{
                if(openDropDown)openDropDown();
              });
            }
          }}
        >
          
          {
            (selectedOption && typeof selectedOption == "object" && isMultiple) ? 
              <View>
                {
                  selectedOption.map((selected) => {
                    return <Text style={{ ...styles.label, paddingLeft: 5, paddingVertical: 5, width: '100%'}}>{selected}</Text>
                  })
                }
              </View>
            :
            <Text style={styles.label}>{selectedOption}</Text>
          }
          <MaterialCommunityIcons
            name="chevron-down"
            size={30}
            color="#9B9B9B"
            style={styles.icon}
          />
        </TouchableOpacity>
        {isVisible ? (
          <View style={styles.itemsContainer}>
            <ScrollView style={{...styles.options, backgroundColor: backgroundColor ? backgroundColor : '#ffff', position: isLast ? 'unset' : 'absolute'}}>
              {options.map((option, idx) => (
                <TouchableOpacity
                  style={styles.item}
                  key={idx}
                  onPress={() => {
                    var idx = -1;
                    var selected = [];
                    if (typeof selectedOption == "object" && isMultiple) {
                      idx = selectedOption.findIndex(selectedO => selectedO == option.value);
                      selected = selectedOption;
                      if (idx >= 0) {
                        selected.splice(idx, 1);
                      } else {
                        selected.push(option.value);
                      }
                    } else {
                      selected = option;
                    }
                    onSelect(selected);
                    this.setState({ selected: selected, isVisible: !isVisible });
                  }}
                >
                  <Text style={styles.label}>{option.value}</Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    position: 'relative',
    zIndex: 2
  },
  itemsContainer:{
    position: 'relative',
    zIndex: 2
},
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    borderWidth: 1,
    borderColor: "#e7e7e7",
    borderRadius: 5,
    paddingLeft: 10,
    height: 60,
  },
  label: {
    fontSize: 16,
    color: "#333",
    fontWeight: "200",
    fontFamily: "Roboto",
    wordBreak: 'break-all',
    width: '100%'
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 5,
  },
  options: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e7e7e7",
    borderRadius: 5,
    borderTopLeftRadius: 0,
    paddingBottom: 5,
    position: 'absolute',
    zIndex: 1,
    width: "100%",
    maxHeight: 200
  },
  item: {
    paddingLeft: 10,
    paddingVertical: 10,
  },
});
