import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import colors from "../../constants/colors";
import NewIssue from "../dialogs/newIssue";
import { Stopwatch } from "react-native-stopwatch-timer";

var update = null;
class Counter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerStart: false,
      startTime: this.props.initial,
      stopwatchStart: false,
      totalDuration: 90000,
      timerReset: false,
      stopwatchReset: false,
      dialogVisible: false,
    };
    this.currentTime = 0;
  }  
  componentDidMount(){
    if(this.props.autoStart && this.state.stopwatchReset == false)
    {  
      this.toggleStopwatch();      
    }
    if(this.props.autoStart && this.props.status == 'approved' || this.props.status == 'complete'){
      this.setState({stopwatchStart: false})
    }
    update = setInterval(() => {
      this.props.onChange(this.props.id, this.currentTime);
      /* if(!this.props.autoStart){
        console.log(this.props.id + " " + this.currentTime);
      } */
    }, 1000);
  }
  
  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({startTime: nextProps.initial});
    }
  }
  componentWillUnmount(){
    clearInterval(update);
  }

  toggleStopwatch = () => {
    this.setState({
      stopwatchStart: !this.state.stopwatchStart,
      stopwatchReset: false,
    });
  };

  resetStopwatch = () => {
    this.currentTime = 0;
    this.setState({ stopwatchStart: false, stopwatchReset: true, startTime: 0 },()=>{
      this.currentTime = 0;
      this.props.onChange(this.props.id, this.currentTime)
    });
  };

  getFormattedTime = (time) => {
    this.currentTime = time;   
    //this.props.onChange(this.props.id, time);
  };
  render() {    
    const { id, isIssuable, onSubmitIssue, isCompleted, label, width, openIssue, defaultComponent } = this.props;
    return (
      <>
      { !width ?
        <NewIssue  
          visible={this.state.dialogVisible} 
          id={id} 
          label={label} 
          onSubmitIssue={onSubmitIssue} 
          handleClose={()=>this.setState({dialogVisible:false})} 
          width={width}
        /> : null
      }

      <View style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-around",
        marginVertical: width ? 0 : 5,
        marginHorizontal: 10,
      }}>
        {
          (this.props.label || this.props.placeholder) && 
          <Text style={styles.label}>
            {this.props.label+" "}
            <Text style={{fontStyle: 'italic',color: colors.primary,fontSize: 18,}}>
              {this.props.placeholder}
            </Text>
          </Text>
        }
        
        {
          isIssuable && 
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            if(width){
              openIssue();
            }else{
              this.setState({dialogVisible:true});
            }
          }}>
            <Text style={{color:colors.primary, fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
            marginVertical: 5,
            
          }}
        >
          <Stopwatch
            start={this.state.stopwatchStart}
            reset={this.state.stopwatchReset}
            startTime={this.state.startTime}          
            options={{
              container: {
                backgroundColor: this.props.autoStart? "lightgreen" :"lightblue",
                padding: 5,
                borderRadius: 5,
                flex: 4,
                height: 45,
                justifyContent: "center",
                alignItems: "center",
              },
              text: {
                fontSize: width == "small" ? 16 : 30,
                color: "#FFF",
                paddingBottom: 5,
              },
            }}
            getMsecs={this.getFormattedTime}
            // getTime={this.getFormattedTime}
          />
          {!this.props.autoStart ?         
          <TouchableOpacity
            style={{ flex: 1, height: 45, paddingHorizontal: 10, justifyContent: "center", alignItems: "center" }}
            disabled={defaultComponent}
            onPress={()=>{
                this.toggleStopwatch();
            }}
          >
            <MaterialCommunityIcons
              name={this.state.stopwatchStart ? "stop" : "play"}
              size={32}
              color={"lightblue"}
              style={styles.icon}
            />
          </TouchableOpacity>:null}
          {!this.props.autoStart ?   
          <TouchableOpacity
            style={{ flex: 1, height: 45, paddingHorizontal: 10, justifyContent: "center", alignItems: "center" }}
            disabled={defaultComponent}
            onPress={this.resetStopwatch}
          >
            <MaterialCommunityIcons
              name="restart"
              size={32}
              color={"lightblue"}
              style={styles.icon}
            />          
          </TouchableOpacity>:null}
        </View>

      </View>
      </>
    );
  }
}
export default Counter;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
